import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

import "../styles/Contact.styl"

export default function contacto() {
  return (
    <Layout>
      <section className="Contact">
        <h1 className="Contact__title Contact__title--center">Contacto</h1>
        <div className="Contact__text Contact__text--center">
          Estamos muy atentos a nuevos mensajes en WhatsApp
        </div>
        <div className="Contact__number Contact__number--small Contact__number--center">
          Whatsapp: 444 554 19 19
        </div>
        <div className="Contact__whatss Contact__whatss--center">
          <a
            href="https://api.whatsapp.com/send?phone=+524445541919&text=Me%20gustar%C3%ADa%20saber%20m%C3%A1s%20de%20sus%20productos"
            target="__blank"
            rel="noopener noreferrer"
            ClassName="Contact__whatss__link"
          >
            <button className="Contact__whatss__link__button">
              Abrir whatsapp
            </button>
          </a>
        </div>
        <div
          className="Contact__text Contact__text--center Contact__email
      "
          data-sal="fade"
          data-sal-delay="1000"
          data-sal-easing="ease"
        >
          Si lo prefieres puedes enviarnos un correo a: <br />
          <span className="Contact__text--strong Contact__email__text">
            <a href="mailto: ventas@photonsolar.com" rel="noopener noreferrer">
              ventas@photonsolar.com.mx
            </a>
          </span>
        </div>

        <div
          className="Contact__text Contact__text--center Contact__text--medium"
          data-sal="slide-up"
          data-sal-delay="1500"
          data-sal-easing="ease"
        >
          Responderemos lo más pronto posible
        </div>

        <div className="Contact__text Contact__text--small Contact__text--center Contact__text--low">
          <Link to="#Footer">consulta nuestros horarios de atención</Link>
        </div>
      </section>
    </Layout>
  )
}
